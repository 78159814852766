import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { AppConfig } from '../shared/app-config';
import { Camera, CameraSource, ImageOptions, CameraResultType, Photo } from '@capacitor/camera';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppCommonService } from './app-common.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  imageChanges: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private actionSheetController: ActionSheetController, private http: HttpClient, private appCommonService: AppCommonService) { }

  geimageChangesSubject$() {
    return this.imageChanges.asObservable();
  }

  async selectImage(category, resourceId, type) {
    const actionSheet = await this.actionSheetController.create({
        header: 'Select Image source',
        buttons: [{
          text: 'Load from Library',
          handler: () => {
            this.takePicture(CameraSource.Photos, category, resourceId, type);
          }
        },
        {
          text: 'Use Camera',
          handler: () => {
            this.takePicture(CameraSource.Camera, category, resourceId, type);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }]
      });
      await actionSheet.present();
  }

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  takePicture(sourceType: CameraSource, category, resourceId, type) {
    const options: ImageOptions = {
      quality: 100,
      source: sourceType,
      saveToGallery: false,
      correctOrientation: true,
      resultType: CameraResultType.Base64
    };
    Camera.getPhoto(options).then(async (data: Photo) => {
      let blob = this.b64toBlob(data['base64String'], 'image/jpeg');
      const formData = new FormData();
      formData.append('file', blob);
      
      this.uploadImage(formData, category, resourceId, type).pipe(catchError(error => {
        let msg = error && error.error && error.error.message;
        this.appCommonService.showError(msg);
        return of(null);
      })).subscribe((resp) => {
        let content = resp && resp.data && resp.data.url;
        if (content) {
          this.updateImageChanges(category, `${AppConfig.IMAGE_UPLOAD_URL}cms/files/${resp.data.id}/download?url=${content}`);
        }
      });
    });
  }
  
  uploadImage(formData: any, category, resourceId, type) {
    return this.http.post<any>(`${AppConfig.IMAGE_UPLOAD_URL}cms/files/upload?category=${category}&category_resource_id=${resourceId}&type=${type}`, formData);
  }

  updateImageChanges(category, url) {
    this.imageChanges.next({category: category, url: url});
  }

  getImage(category, resourceId) {
    this.http.get<any>(`${AppConfig.IMAGE_UPLOAD_URL}cms/files?category=${category}&category_resource_id=${resourceId}`).subscribe(resp => {
        let content = resp && resp.content && resp.content.length && resp.content[0] && resp.content[0].url;
        if (content) {
          this.updateImageChanges(category, `${AppConfig.IMAGE_UPLOAD_URL}cms/files/${resp.content[0].id}/download?url=${content}`);
        }
    });
  }
}
