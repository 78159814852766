import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-insufficient-balance',
  templateUrl: './insufficient-balance.component.html',
  styleUrls: ['./insufficient-balance.component.scss'],
})
export class InsufficientBalanceComponent implements OnInit {

  @Input() amount: number;

  constructor(private popoverCtrl: PopoverController, private router: Router) { }

  ngOnInit() {}

  close() {
    this.popoverCtrl.dismiss();
  }

  proceed() {
    this.popoverCtrl.dismiss();
    this.router.navigate(['/add-money'], {queryParams: {amount: this.amount, showInfoPopup: 'true'}});
  }
  
}
