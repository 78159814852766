import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
  public static API_URL = environment.apiUrl;

  public static REFRESH_OTP_TIME_SEC = 60;

  static readonly SERVERS = [
    {
      name: 'DEV',
      url: 'https://api-gateway-dev.evnnovator.com',
    },
    {
      name: 'INT',
      url: 'https://api-gateway-int.evnnovator.com',
    },
    {
      name: 'TEST',
      url: 'https://api-gateway-test.evnnovator.com',
    },
    {
      name: 'PROD',
      url: 'https://api-gateway-prod.evnnovator.com',
    },
  ];

  public static getServerName() {
    for (let item of AppConfig.SERVERS) {
      if (item.url === AppConfig.API_URL) {
        return item.name;
      }
    }
  }

  public static getRazorPayClientId() {
    return AppConfig.API_URL === 'https://api-gateway-test.evnnovator.com' ||
      AppConfig.API_URL === 'https://api-gateway-prod.evnnovator.com'
      ? 'rzp_live_aFwzyTZrqb8kFV'
      : 'rzp_test_17kYJXxNXqfkfJ';
  }

  public static updateServerUrl(url) {
    AppConfig.API_URL = url;
  }

  static get DRIVER_URL() {
    return AppConfig.API_URL + '/api/user/v1';
  }

  static get IMAGE_LIST() {
    return AppConfig.API_URL + '/api/cms/v1/cms/files?';
  }

  static get USER_URL() {
    return AppConfig.API_URL + '/api/user/v1/users/';
  }

  static get ADMIN_URL() {
    return AppConfig.API_URL + '/api/user/v1/';
  }

  static get OCPI_URL() {
    return AppConfig.API_URL + '/api/ocpi/v1/';
  }

  static get BILLING_URL() {
    return AppConfig.API_URL + '/api/billing/v1/';
  }

  static get REIVIEW_URL() {
    return AppConfig.API_URL + '/api/review/v1/';
  }

  static get SUPPORT_URL() {
    return AppConfig.API_URL + '/api/support/v1/';
  }

  static get IMAGE_UPLOAD_URL() {
    return AppConfig.API_URL + '/api/cms/v1/';
  }

  static get NOTIFICATIONS_URL() {
    return AppConfig.API_URL + '/api/notification/v1/';
  }

  static get EMSP_CONFIG_URL() {
    return (
      AppConfig.API_URL +
      '/api/user/v1/emsp/config/e925731ff7b70acde478591f1327bd1b'
    );
  }

  static get TOKEN_URL() {
    return (
      AppConfig.API_URL +
      '/auth/oauth/token?grant_type=refresh_token&refresh_token='
    );
  }

  static get SOCIAL_LOGIN_URL() {
    return (
      AppConfig.API_URL +
      '/auth/oauth2/authorize/google?redirect_uri=http://localhost:8100/social-login'
    );
  }

  //public static SOCIAL_LOGIN_URL = AppConfig.API_URL + '/auth/oauth2/authorize/google?redirect_uri=https://dev.fich.digital/social-login';
  //  public static  IMAGE_UPLOAD_URL = AppConfig.API_URL + '/api/cms/v1/';

  // public static firebasConfig = {
  //    apiKey: "AIzaSyDXf7SVxZy_E33xDQAVx63BQGVc4oUDihk",
  //    authDomain: "evnnovator-dd024.firebaseapp.com",
  //    databaseURL: "https://evnnovator-dd024-default-rtdb.asia-southeast1.firebasedatabase.app",
  //    projectId: "evnnovator-dd024",
  //    storageBucket: "evnnovator-dd024.appspot.com",
  //    messagingSenderId: "1084277787630",
  //    appId: "1:1084277787630:web:7d61a08847f45c3c3edcff",
  //    measurementId: "G-N45V5BKQC9"
  //  };
}
