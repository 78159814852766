import { Injectable } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { AddVehiclePopupComponent } from '../components/alerts/add-vehicle-popup/add-vehicle-popup.component';
import { AddWalletBalancePopupComponent } from '../components/alerts/add-wallet-balance-popup/add-wallet-balance-popup.component';
import { InsufficientBalanceComponent } from '../components/alerts/insufficient-balance/insufficient-balance.component';

@Injectable({
  providedIn: 'root'
})
export class AppCommonService {

  private signInFlow = false;
  private showAddVehicle = false;
  private fleetSignInFlow = false;
  private showAddWalletBalance = false;
  private backScreenCount = 0;
  isProfileOpen = false;
  isVehicleOpen = false;

  constructor(private popoverCtrl: PopoverController, private toastController: ToastController) { }

  isFleetSignIn() {
    return this.fleetSignInFlow;
  }

  setFeetSingInFlow(val) {
    this.showAddVehicle = val;
    this.fleetSignInFlow = val;
  }

  isSignInFlow() {
    return this.signInFlow;
  }

  setProfilePopUp(val){
    this.isProfileOpen = val;
  }

  getProfilePopUp(){
    return this.isProfileOpen;
  }

  setVehiclePopUp(val){
    this.isVehicleOpen = val;
  }

  getVehiclePopUp(){
    return this.isVehicleOpen;
  }

  setSignInFlow(isSignInFlow = false) {
    if (this.signInFlow && !isSignInFlow) {
      this.showAddVehicle = true;
      this.showAddWalletBalance = true;
    }
    this.signInFlow = isSignInFlow; 
  }

  showAddVehiclePopup() {
    if (this.showAddVehicle) {
      setTimeout(async () => {
        const popover = await this.popoverCtrl.create({
          component: AddVehiclePopupComponent,
          cssClass: 'alert-popover-width',
          translucent: true
        });
        await popover.present();
      }, 1000);
      this.showAddVehicle = false;
    }
  }

  showAddWalletBalancePopup() {
    if (this.showAddWalletBalance) {
      setTimeout(async () => {
        const popover = await this.popoverCtrl.create({
          component: AddWalletBalancePopupComponent,
          cssClass: 'alert-popover-width',
          translucent: true
        });
        await popover.present();
      }, 1000);
      this.showAddWalletBalance = false;
    }
  }

  getBackScreenCount() {
    let count = this.backScreenCount;
    this.backScreenCount = 0;
    return count;
  }

  setBackScreenCountsetBack(count) {
    this.backScreenCount = count;
  }

  async showInsufficientBalance(amount, count = 0) {
    this.setBackScreenCountsetBack(count);
    const popover = await this.popoverCtrl.create({
      component: InsufficientBalanceComponent,
      cssClass: 'alert-popover-width',
      componentProps: {amount: amount},
      translucent: true
    });
    await popover.present();
  }

  async showError(message) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 2000,
      cssClass: 'custom-toast',
      buttons: [{
          text: '✖',
          role: 'cancel'
      }]
    });
    toast.present();
}

}
