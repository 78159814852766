import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'add-wallet-balance-popup',
  templateUrl: './add-wallet-balance-popup.component.html',
  styleUrls: ['./add-wallet-balance-popup.component.scss'],
})

export class AddWalletBalancePopupComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, private router: Router) { }

  ngOnInit() {}

  close() {
    this.popoverCtrl.dismiss();
  }

  navigateToWallet() {
    this.popoverCtrl.dismiss();
    this.router.navigate(['/add-money'], {queryParams: {amount: 500}});
  }

}
